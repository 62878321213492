import { React, Fragment } from 'react';

function Block() {
  return (
    <Fragment>
      <div className="words word-1">
        <span>S</span>
        <span>O</span>
        <span>M</span>
        <span>O</span>
        <span>S</span>
        <span>&nbsp;</span>
        <span>U</span>
        <span>N</span>
        <span>A</span>
        <span>&nbsp;</span>
        <span>E</span>
        <span>M</span>
        <span>P</span>
        <span>R</span>
        <span>E</span>
        <span>S</span>
        <span>A</span>
        <span>&nbsp;</span>
        <span>D</span>
        <span>E</span>
        <span>D</span>
        <span>I</span>
        <span>C</span>
        <span>A</span>
        <span>D</span>
        <span>A</span>
        <span>&nbsp;</span>
        <span>A</span>
        <span>&nbsp;</span>
        <span>L</span>
        <span>A</span>
        <span>&nbsp;</span>
        <span>I</span>
        <span>M</span>
        <span>P</span>
        <span>O</span>
        <span>R</span>
        <span>T</span>
        <span>A</span>
        <span>C</span>
        <span>I</span>
        <span>Ó</span>
        <span>N</span>
        <span>&nbsp;</span>
        <span>D</span>
        <span>E</span>
        <span>&nbsp;</span>
        <span>P</span>
        <span>R</span>
        <span>O</span>
        <span>D</span>
        <span>U</span>
        <span>C</span>
        <span>T</span>
        <span>O</span>
        <span>S</span>
        <span>&nbsp;</span>
        <span>D</span>
        <span>E</span>
        <span>&nbsp;</span>
        <span>A</span>
        <span>L</span>
        <span>T</span>
        <span>A</span>
        <span>&nbsp;</span>
        <span>C</span>
        <span>A</span>
        <span>L</span>
        <span>I</span>
        <span>D</span>
        <span>A</span>
        <span>D</span>
        <span>&nbsp;</span>
        <span>A</span>
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    </Fragment>
  );
}

export default Block;