import { React } from 'react';

function Logo() {
  return (
    <div className='image-globe'>
      <img src='https://i.gifer.com/W31X.gif' alt='Globe' />
    </div>
  );
}

export default Logo;