import React from 'react';

function Title(){
    return (
        <section>
            <div className="title">
            <h3>Desde el</h3>
            <h1>MUNDO</h1>
            </div>
        </section>
        );
}

export default Title;